
import { defineComponent, computed } from 'vue';
import { localizedRoute } from '@vue-storefront/core/lib/multistore';
import { formatRegionUrl } from '$modules/shipping/utils/region-url';
import MProductCarousel from 'theme/components/molecules/m-product-carousel.vue';
import { SfLink } from '@storefront-ui/vue';
import OSection from 'theme/components/organisms/o-section.vue';

interface SpecialCategory {
  id: string | number,
  name: string,
  url_path: string,
  products: any[]
}

export default defineComponent({
  name: 'HomeSpecialCategories',
  components: {
    MProductCarousel,
    SfLink,
    OSection
  },
  props: {
    sampleSpecialCategories: {
      type: Array as () => SpecialCategory[],
      required: true
    }
  },
  setup (props) {
    return {
      localizedRoute,
      formatRegionUrl,
      sampleSpecialCategories: computed(() => props.sampleSpecialCategories)
    }
  }
})
